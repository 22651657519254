<template>
  <v-container fluid>
    <v-toolbar
        color="white"
        flat
    >

      <v-toolbar-title class="grey--text text--darken-4">
        Overzicht seizoenen
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-list >
      <v-list-item link :to="`season/`+a.ID" v-for="a in seasons" :key="a.ID">
        <v-list-item-content >
          <v-list-item-title>{{ a.Name }}</v-list-item-title>
          <v-list-item-action-text></v-list-item-action-text>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>

import {Season} from "@/models/season";
const {SeasonService} = require("@/services/season-service");
export default {
  data(){
    return{
      seasons: Array[Season] = []
    }
  },
  async created() {
    this.seasons = await SeasonService.get(1);

  }
}
</script>

<style scoped>
  .v-list-item:first-child{ border-top: 1px solid rgba(0, 0, 0, 0.12); }
  .v-list-item{ border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
</style>